import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ManageDeletedService {

  url: any = environment.API_URL;


  constructor(
    private http: HttpClient

  ) { }

  
  getDeletedList(page: number, pageLimit: number) {
    const data = {
      page: page,  // Include the page parameter in the payload
      pageLimit: pageLimit,
    };
  
    return this.http.post(this.url + 'api/admin/list-of-deleted-users', data);
  }

  }

