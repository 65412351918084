

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as _  from 'lodash';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()

export class ManageCaregiversService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    public exportAsExcelFile(json: any[], excelFileName: string): void {
        console.log(json ,"xl")
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
            console.log('worksheet',worksheet);
            const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            console.log(workbook,"wb")
            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            console.log(workbook,"excelbuffer")
    
            
            this.saveAsExcelFile(excelBuffer, excelFileName);
          }
        
          private saveAsExcelFile(buffer: any, fileName: string): void {
            const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
          }

    getNotificationList(data: any) {
        return this.http.post(this.url + 'notification/getNotifications', data);
    }

    createNotification(data: any) {
        return this.http.post(this.url + 'notification/saveNotification', data);
    }

    deleteNotification(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'notification/deleteNotification', httpOptions);
    }

    updateNotification(data : any) {
        return this.http.post(this.url + "notification/updateNotification", data);
    }

    getContacted(data : any) {
        return this.http.post(this.url + "api/waitlist/update", data);
    }

  

    approveCaregiver(data:any){
        return this.http.post(this.url + "user/approve-caregiver", data);
    }


    deleteService(data:any){
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'admins/delete-preventMeasure', httpOptions);
    }

    setStatus(data:any){
        return this.http.post(this.url + "user/change-status", data);
    }

    getServiceBySearch(data:any) {
        return this.http.post(this.url + "user/change-status", data);
    }
    
  addPreventive(data: any) {
    return this.http.post(this.url + 'admins/add-preventMeasure', data);
  }

  getWaitingList(data) {
    return this.http.post(this.url + 'api/waitlist/list', data);
  }

  editService(data:any){
    return this.http.post(this.url + 'admins/edit-preventMeasure', data);
}

}