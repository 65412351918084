import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  constructor() { 
    //intentionally left space for future use

  }

  ngOnInit(): void {
    //intentionally left space for future use
  }

}
