
<body>
<form [formGroup]="resetForm" (submit)="resetPassword()">
    <div class="customImageContainer">
      <img class="customImage" src="../../../../assets/images/Reset%20Background.png" alt="Your Image" />
    </div>
    <div class="resetPasswordContainer">
      <img class="resetPasswordImage" src="../../../../assets/images/Reset Password.png" alt="Your Image">
      <div class="contentInsideImage">
        <img src="../../../../assets/images/reset logo.png" alt="Your Image" />
        <h2>Reset Password</h2>
        <p style="color: darkgray;">Enter the email address associated with <br> your account</p>
        <div class="form-group d-flex borderStyles" style="margin-bottom: 30px;">
          <img src="../../../../assets/images/Email.png" alt="" class="img-card">
          <input type="email" class="form-control inputStyles rightcard1" placeholder="Email Address" formControlName="email">
        </div>
        <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
          <span *ngIf="f.email.errors.required">
            
            Email Required
          </span>
          <span *ngIf="f.email.errors.email">
            Invalid Email
          </span>
        </div>
        <button type="submit" class="btn btn-primary signInBtn" style="background-color: #8261C2;">Reset Password</button>
        <div class="back-signin" routerLink="/">
            <span>Back to </span><span style="color: #8261C2;">Sign In</span>
          </div>
          
      </div>
    </div>
  </form>
</body>
  
  <ng-template #template>
    <!-- <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center delete-modal">
        <img src="../../../../assets/images/Reset Password.png" alt="">
      <p>Password sent to your<br />email address</p>
      <div class="modal-btn">
        <button class="btn btn-cancel btn-primary" (click)="OkPress()" style="background-color: #346ef6;">OK</button>
       
      </div>
    </div> -->
   <!-- Your component template file (e.g., app.component.html) -->

<div class="background-overlay">
  <div class="popup-container">
    <p>Password sent to your<br />email address</p>
    <button (click)="OkPress()">OK</button>
  </div>
</div>

</ng-template> 
  
  