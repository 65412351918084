<app-layout></app-layout>

 <div class="content">
  <app-manage-loader *ngIf="showLoader"></app-manage-loader>

  <form #f="ngForm" (ngSubmit)="onSubmit(f)" class="search-form" style="border: 2px solid rgba(255, 255, 255, 0.4); background-color: rgba(30, 36, 42, 1); padding: 8px; margin-top: 144px; display: flex; align-items: center; border-radius: 30px;margin-right: -166px;height:42px">
    <img src="../../../../../assets/icons/search-icon.svg" alt="" style="margin-left: 15px;">
    <input style="padding-left: 15px; border: none; outline: none; flex: 0.9; height: 30px;width: 250px; background-color: rgba(30, 36, 42, 1); color: white;"
    [readOnly]="readOnly"
    (focus)="removeRead()"  
    class="form-control" 
    id="search" 
    name="search" 
    type="text" 
    [(ngModel)]="searchText" 
    autocomplete="off" 
    placeholder="Search" 
    aria-label="Search" 
    (keyup)="checkEmpty($event)">
</form>





<div style="display: flex; width: 100%; justify-content: space-around; border-bottom: 2px solid rgba(96, 111, 140, 1);">
  <div class="tab" (click)="showTabContent(1)" [class.selected]="selTab === 1">Head Coach</div>
  <div class="tab" (click)="showTabContent(2)" [class.selected]="selTab === 2">Assistant Coach</div>
  <div class="tab" (click)="showTabContent(3)" [class.selected]="selTab === 3">Head Negotiator</div>
  <div class="tab" (click)="showTabContent(4)" [class.selected]="selTab === 4">Waiver Wizard</div>
  <div class="tab" (click)="showTabContent(5)" [class.selected]="selTab === 5">Squad Member</div>
  <div class="tab" (click)="showTabContent(6)" [class.selected]="selTab === 6">Anonymous users</div>
</div>




  <div class="filter-container">
    <div class="filter-header" (click)="toggleDropdown()">
      <span style="margin-top: 23px; font-weight: bold; font-size: 19px;">Filter By: League Name</span>
      <img src="../../../assets/images/Vector (3).png" alt="Dropdown Icon" [class.rotate]="dropdownOpen" style="margin-left: 10px; margin-top: -3px;"/>
    </div>
  
    <div class="dropdown-content" id="dropdownContent" [ngClass]="{'show': dropdownOpen}"
         style="width: 415px; background: #374351; box-shadow: 0px 42.444149017333984px 61.30821228027344px rgba(0, 0, 0, 0.08); flex-direction: column; justify-content: flex-start; align-items: flex-start; row-gap: 2px; border-radius: 8px; margin-top: 15px; padding-bottom: 10px;max-height: 300px; overflow-y: auto;">
      <div *ngFor="let item of items" 
           (click)="selectItem(item)" 
           style="width: 370px; padding: 10px; border-radius: 10px; justify-content: flex-start; align-items: flex-start; display: inline-flex; background-color: transparent;margin-left: 20px;margin-top: 10px;" 
           onmouseover="this.style.backgroundColor='#2D3649';" 
           onmouseout="this.style.backgroundColor='transparent';">
        <div style="color: white; font-size: 16px; font-family: 'Plus Jakarta Sans', sans-serif; font-weight: 500; line-height: 26px; word-wrap: break-word;">{{item?.name}}</div>
        <div style="margin-left: auto;">
          <img [src]="selectedItem === item ? '../../../assets/images/Vector (4).png' : ''" alt="" />
        </div>
      </div>
    </div>
  </div>
  
  

  
  
  <br />
  <h2 style="text-align: right;font-size: 19px;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;margin-top: -42px;">Total Members : <span style="font-size: 21px;"><button class="custom-button">{{ totalCount }}</button></span></h2>
  


  <div class="skill-tabs d-flex justify-content-between align-items-top mt-2" >
    <div class="table-container" >
      <table class="table mt-2"  aria-label="Description of the table" >
      
        <thead >
        
          <tr>
            <th  scope="col" class="font-weight-bold" style="font-size: 18px; color: white;text-align: center; border-top-left-radius: 10px;   border-right: 1px solid rgba(96, 111, 140, 0.4);font-family: sans-serif;white-space: nowrap;">Sr. No.</th>
            <th scope="col" class="font-weight-bold" style="font-size: 18px; color: white;text-align: center;  border-right: 1px solid rgba(96, 111, 140, 0.4);white-space: nowrap;">Created Date</th>

            <th scope="col" class="font-weight-bold" style="font-size: 18px; color: white;text-align: center;  border-right: 1px solid rgba(96, 111, 140, 0.4);">Name</th>

            <th scope="col" class="font-weight-bold" style="font-size: 18px; color: white;text-align: center;  border-right: 1px solid rgba(96, 111, 140, 0.4);white-space: nowrap;">Position</th>
  
            <!-- <th scope="col" class="font-weight-bold" style="font-size: 18px; color: white;text-align: center;  border-right: 1px solid rgba(96, 111, 140, 0.4);white-space: nowrap;">Profile Picture</th> -->
            <th scope="col" class="font-weight-bold" style="font-size: 18px; color: white; text-align: center; border-right: 1px solid rgba(96, 111, 140, 0.4); white-space: nowrap;">League Name</th>
  
            
            
           
              <!-- <th scope="col" class="font-weight-bold" style="font-size: 18px; color: white;text-align: center;white-space: nowrap;">Last Login
              </th>
              <th scope="col" class="font-weight-bold" style="font-size: 18px; color: white;text-align: center; border-top-right-radius: 10px;white-space: nowrap;">Last Logout
              </th> -->
          </tr>
        </thead>  
        <tbody style="background-color: rgba(30, 36, 42, 1);">
          <tr *ngFor="let client of dashboardList; let i = index" (click)="selectClient(client)">
            <td style="color: white;">{{(page - 1) * itemsPerPage + i + 1}}.</td>
            <td style="color: white; text-align: center; font-size: 14px;white-space: nowrap;">{{ client?.createdAt ? formatDate(client.createdAt) : 'N/A' }}</td>

          <td class="hover-color" (click)="openPopupforDetails(template)">{{ client?.name ? client.name : 'N/A' }}</td>

            <td style="color: white; text-align: center; font-size: 14px;white-space: nowrap;"> {{ positions[selTab - 1] }}</td>
  
          
  
           
            <!-- <td style="color: white; font-family: 'Plus Jakarta Sans', sans-serif;">

            <div class="image-container">
              <img src="../../../assets/images/Usa.png" alt="'" id="popupImage" (click)="ViewUploadImage()" />
          </div>
            </td> -->

            <!-- <td style="color: white; font-family: 'Plus Jakarta Sans', sans-serif;">
              <div class="image-container">
                <img 
                  [src]="client?.userInfo?.profilePic || '../../../assets/images/base_logo_transparent_background (2).png'" 
                  alt=" " 
                  style="border-radius: 50%; height: 30px; width: 50px;" 
                  (click)="ViewUploadImage(client)"
                />
              </div>
            </td> -->
            
           
            <td class="description-cell" style="color: white;font-size: 14px">
              <div>
                <p style="color: white;white-space: nowrap;">{{ client?.leagueInfo?.name ? client.leagueInfo?.name : 'N/A' }}</p>
              </div>
            </td>
           
            <!-- <td>
              <div class="d-flex align-items-center">
                  <div style="color: white; text-align: center;font-size: 14px;">{{ client?.lastLogin ? client?.lastLogin : 'N/A' }}</div>
              </div>
          </td>
          <td>
            <div class="d-flex align-items-center">
                <div style="color: white; text-align: center;font-size: 14px;">{{ client?.lastLogin ? client?.lastLogin : 'N/A' }}</div>
            </div>
        </td> -->
          </tr>
        </tbody>
      </table>
    </div>
  
  
  </div>
  <div style="margin-top: 17px;">
    <pagination  *ngIf="!notFound && totalCount > itemsPerPage" [boundaryLinks]="true" [totalItems]="totalCount"
      [itemsPerPage]="itemsPerPage" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"
      previousText="&lsaquo;" nextText="&rsaquo;" [maxSize]="10" firstText="&laquo;" lastText="&raquo;" >
  </pagination>
</div>
</div>

<ng-template #templateImage1>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal">
    <img 
    [src]="selectedProfilePicture" 
      alt="" 
      height="400" 
      width="100%"
    />
  </div>
</ng-template>


<div id="partners">
  <ng-template #template>
    <div class="custom-popup-wrapper" style="background-color: #2d3649; color: #ffffff;" >
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style="margin-left: 40px;">
        <p class="left-column" style="font-size: 18px;margin-top: -30px;">User Details</p>
        <img src="../../../assets/images/Usa.png" style="width: 100px; height: 100px; background-color: white;
        display: inline-block; 
        padding: 4px; 
        object-fit: cover;
        margin-top: 20px;
        border-radius: 50px;
      " alt=""/>
      </div>
      <div class="details-container">
     
        <div class="left-details">
            <div class="custom-popup-content" style="background-color: #2d3649; color: #ffffff;">
            
              <div class="column-pair">
                <p class="left-column">Name</p>
                <p class="right-column">:</p>
            </div>
            <div class="column-pair">
              <p class="left-column">Experience</p>
              <p class="right-column">:</p>
          </div>
          <div class="column-pair">
            <p class="left-column">Email</p>
            <p class="right-column">:</p>
          </div>
          <!-- <div class="column-pair">
            <p class="left-column">Last Login</p>
            <p class="right-column">:</p>
        </div>
        <div class="column-pair">
          <p class="left-column">Last Logout</p>
          <p class="right-column">:</p>
      </div> -->
      <div class="column-pair">
        <p class="left-column">Position</p>
        <p class="right-column">:</p>
    </div>
    <div class="column-pair">
      <p class="left-column">Created Date</p>
      <p class="right-column">:</p>
  </div>
  
<div class="column-pair">
  <p class="left-column">League Name</p>
  <p class="right-column">:</p>
</div>

              
            </div>
        </div>
    
       
        <div class="right-details">
            <div class="custom-popup-content" style="background-color: #2d3649; color: #ffffff;">
      
                <p class="right-column">{{ selectedClient?.name }}</p>
                <p class="right-column">{{ selectedClient?.userInfo?.experience }}</p>
                <p class="right-column">{{ selectedClient?.userInfo?.email }}</p>
                <p class="right-column">{{ selectedClient?.userInfo?.login  || 'N/A'}}</p>
                <!-- <p class="right-column">{{ selectedClient?.userInfo?.login  || 'N/A' }}</p>
                <p class="right-column">{{ selectedClient?.userInfo?.login  || 'N/A' }}</p> -->
                <p class="right-column">{{ selectedClient?.createdAt }}</p>
                <p class="right-column">{{ selectedClient?.leagueInfo?.name }}</p>
            </div>
        </div>
    </div>
    
    </div>
    
    

  </ng-template>
</div>

