 <app-layout></app-layout> 
 <div class="content">
  <app-manage-loader *ngIf="showLoader"></app-manage-loader>
  
    <div class="news-header">
      
    </div>
        <div class="skill-tabs d-flex justify-content-between align-items-top mt-2">
          <div class="table-container">
            <table class="table mt-2" aria-label="Description of the table">
              <thead>
                <tr>
                    <th scope="col" class="font-weight-bold" style="font-size: 18px; color: white; text-align: center; border-top-left-radius: 10px;">Sr. No.</th>
                    <th scope="col" class="font-weight-bold" style="font-size: 18px; color: white; text-align: center;">Name</th>
                    <th scope="col" class="font-weight-bold" style="font-size: 18px; color: white; text-align: center;">Email</th>
                    <th scope="col" class="font-weight-bold" style="font-size: 18px; color: white; text-align: center;">Role</th>
                    <th scope="col" class="font-weight-bold" style="font-size: 18px; color: white; text-align: center;">Account Created Date</th>
                    <th scope="col" class="font-weight-bold" style="font-size: 18px; color: white; text-align: center;">Last Login</th>
                    <th scope="col" class="font-weight-bold" style="font-size: 18px; color: white; text-align: center;">Deletion</th>
                  </tr>
              </thead>
              <tbody style="background-color: rgba(30, 36, 42, 1);">
                <tr *ngFor="let client of deletedList; let i = index">
                    <td style="color: white;">{{(page - 1) * itemsPerPage + i + 1}}.</td>
                    <td style="color: white;">{{ client?.firstName ? client?.firstName : 'N/A' }}</td>
                    <td style="color: white;">{{ client?.email ? client?.email : 'N/A' }}</td>
                    <td [ngSwitch]="client?.userType" style="color: white;">
                      <ng-container *ngSwitchCase="1">Admin</ng-container>
                      <ng-container *ngSwitchCase="2">Guest User</ng-container>
                      <ng-container *ngSwitchCase="3">League Admin</ng-container>
                      <ng-container *ngSwitchCase="4">Coaching Squad</ng-container>
                      <ng-container *ngSwitchDefault>N/A</ng-container>
                    </td>
                    
                    <td style="color: white;">{{ client?.createdAt ? convertDateTimeFormat(client?.createdAt) : 'N/A' }}</td>
                    <td style="color: white;">{{ client?.updatedAt ? convertDateTimeFormat(client?.updatedAt) : 'N/A' }}</td>
                    <td style="color: white;">{{ client?.updatedAt ? convertDateTimeFormat(client?.updatedAt) : 'N/A' }}</td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
  </div>