import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class DashboardService {
    url: any = environment.API_URL;
    header = {};

    constructor(
        private http: HttpClient
    ) { }

    getDashboard(data:any){
        return this.http.post(this.url + "appList/getDashboardAnalytics", data );
    }


    getDownloadAppList(){
        return this.http.get(this.url + "appList/getTotalAppsDownloaded");
    }


    getActiveUsers(){
        return this.http.get(this.url + "appList/getHospitalsUsers");
    }

    getTotalForms(){
        return this.http.get(this.url + "appList/getTotalForms");
    }

    getAgeGroup(data){
        return this.http.post(this.url + "appList/getAgeGroup", data);
    }

    deviceCount(){
        return this.http.get(this.url + "appList/getDeviceCountByHospital");
    }

    getDeviceModel(id:any){
        return this.http.post(this.url + "appList/getDeviceListsByHospital", id);
    }

    getLeague(data) {
        return this.http.post(this.url + "api/league/league-list-for-admin",data);
    }

    getDashboardList(data) {
        return this.http.post(this.url + "api/admin/get-reporting-dashboard-data",data);
    }

    // getDashboardList(page: number, pageLimit: number, searchKey: any) {
    //     const data = {
    //         page: page,
    //         pageLimit: pageLimit,
    //         searchKey: searchKey
    //       };
    //     return this.http.post(this.url + "api/admin/get-reporting-dashboard-data",data);
    // }

   

}