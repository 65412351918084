import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-send',
  templateUrl: './email-send.component.html',
  styleUrls: ['./email-send.component.scss']
})
export class EmailSendComponent implements OnInit {

  constructor() {
    //intentionally left space for future use

   }

  ngOnInit(): void {
    //intentionally left space for future use
  }

}
