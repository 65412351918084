<div class="sidebar" style="background-color: rgba(45, 54, 73, 1);border: none;">

  <div class="logo mt-2">
    <img src="../../../assets/images/base_logo_transparent_background (2).png" alt="logo" style="object-fit: contain;height: 74px;margin-top: 20px;margin-bottom: 35px;">
  </div>

  <div class="header-links">
    <div class="sidebar-menu">
      <div class="menu-item" (click)="toggleSubMenu('fantasy')">
        <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/images/users.png" alt="" height="24" width="24">
        </span>
        <span class="link-text">Fantasy Sports Details</span>
        <img *ngIf="!isFantasySubMenuOpen" src="../../../assets/icons/greyicon.png" alt="" class="menu-icon" />
        <img *ngIf="isFantasySubMenuOpen" src="../../../assets/icons/greyicon.png" alt="" class="menu-icon" style="transform: rotate(180deg);" />
      </div>
      <div id="submenu-fantasy" [style.display]="isFantasySubMenuOpen ? 'block' : 'none'">
        <a routerLink="/Sports-type" routerLinkActive="active-link" class="submenu-link">
          <span class="sidebar-icon">
            <img class="white-icon" src="../../../assets/images/users.png" alt="" height="24" width="24">
          </span>
          <span class="link-text">Sports Type</span>
        </a>
      </div>

      <div class="menu-item" (click)="toggleSubMenu('controls')">
        <span class="sidebar-icon">
            <img class="white-icon" src="../../../assets/images/noun-rugby-helmet-5193403 1.png" alt="" height="24" width="24">
        </span>
        <span class="link-text">Fantasy Sports Controls</span>
        <img *ngIf="!isControlsSubMenuOpen" src="../../../assets/icons/greyicon.png" alt="icon" class="menu-icon"/>
        <img *ngIf="isControlsSubMenuOpen" src="../../../assets/icons/greyicon.png" alt="icon" class="menu-icon active-arrow" />
    </div>
    <div id="submenu-controls" [style.display]="isControlsSubMenuOpen ? 'block' : 'none'">
        <a routerLink="/league-category" routerLinkActive="active-link" class="submenu-link">
            <span class="sidebar-icon">
                <img class="white-icon" src="../../../assets/images/users.png" alt="" height="24" width="24">
            </span>
            <span class="link-text">League Category</span>
        </a>
    </div>
    

      <a routerLink="/Leagues-list" routerLinkActive="active-link" class="menu-item">
        <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/images/Home.png" alt="" height="24" width="24">
        </span>
        <span class="link-text">Leagues</span>
      </a>

      <div class="menu-item" (click)="toggleSubMenu('reporting')">
        <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/images/Group.png" alt="" height="24" width="24">
        </span>
        <span class="link-text">User Information</span>
        <img *ngIf="!isReportingOpen" src="../../../assets/icons/greyicon.png" alt="" class="menu-icon"/>
        <img *ngIf="isReportingOpen" src="../../../assets/icons/greyicon.png" alt="" class="menu-icon" style="transform: rotate(180deg);" />
      </div>
      <div id="reporting-menu" [style.display]="isReportingOpen ? 'block' : 'none'">
        <a routerLink="/analytics-dashboard" routerLinkActive="active-link" class="submenu-link">
          <span class="sidebar-icon">
            <img class="white-icon" src="../../../assets/images/Home.png" alt="" height="24" width="24">
          </span>
          <span class="link-text">Reporting</span>
        </a>
      </div>

      <a routerLink="/Waitlist-form" routerLinkActive="active-link" class="menu-item">
        <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/images/noun-trade-4257124 1.png" alt="" height="24" width="24">
        </span>
        <span class="link-text">View Waitlist Forms</span>
      </a>

      <a routerLink="/league-admin" routerLinkActive="active-link" class="menu-item">
        <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/images/league1.png" alt="" height="24" width="24">
        </span>
        <span class="link-text">Manage League Admin</span>
      </a>

      <a routerLink="/manage-deleted" routerLinkActive="active-link" class="menu-item">
        <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/images/users.png" alt="" height="24" width="24">
        </span>
        <span class="link-text">Deleted Users List</span>
      </a>

      <a (click)="openModal(template)" routerLinkActive="active-link" id="logout" class="menu-item">
        <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/images/logout.png" alt="" height="24" width="24">
        </span>
        <span class="link-text">Logout</span>
      </a>
    </div>

    <ng-template #template>
      <div class="modal-body text-center delete-modal align-items-center" style="background-color: rgba(45, 54, 73, 1);">
        <button type="button" class="close close-button-black-border" aria-label="Close" (click)="modalRef.hide()" routerLink="/careers/list">
          <span aria-hidden="true" class="logIcon">&times;</span>
        </button>
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
        <p style="color: white;">Are you sure you <br>want to logout</p>
        <div class="modal-btn d-flex justify-content-around">
          <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
          <button class="btn btn-blue" (click)="confirm()" style="background-color: #8261C2;">Yes</button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
