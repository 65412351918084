<div class="header" style="background-color: rgba(45, 54, 73, 1);">

    <div class="left-sec" >
        <ng-container *ngIf="welcomeUserMsg">
            <h1 style="color: white;font-size: 25px;">Dashboard</h1>
        </ng-container>
        <h1 *ngIf="manageCaregiversHeading" style="color: white;font-size: 25px;">WaitList Forms</h1>
        <h1 *ngIf="manageClientHeading">Manage Businesses</h1>
        <h1 *ngIf="manageServicesHeading" style="color: white;font-size: 25px;">Manage League Admin</h1>
        <h1 *ngIf="manageTutorialHeading">Manage Tutorial</h1>
        <h1 *ngIf="manageConvenienceHeading">Manage Convenience Fee</h1>
        <h1 *ngIf="manageDiseasesHeading" style="color: white;font-size: 25px;">Sports Type</h1>
        <h1 *ngIf="manageSubCategoriesHeading" style="color: white;font-size: 25px;">League Category</h1>
        <h1 *ngIf="manageTasksHeading">Home Health Tasks</h1>
        <h1 *ngIf="managePreventiveHeading" >Preventive Measures</h1>
        <h1 *ngIf="manageTasksViewHeading">Home Health Tasks</h1>
        <h1 *ngIf="manageLocalHeading">Home Health Assessment</h1>
        <h1 *ngIf="dashboardHeading">Dashboard</h1>
        <h1 *ngIf="changePasswordHeading">Change Password</h1>
        <h1 *ngIf="texHeading" style="color: white;font-size: 25px;">Leagues List</h1>
        <h1 *ngIf="manageanalyticsviewHeading" style="color: white;font-size: 25px;">Leagues Details</h1>
        <h1 *ngIf="managedeletedHeading" style="color: white;font-size: 25px;">Deleted User</h1>
        <h1 *ngIf="manageplayoffHeading" style="color: white;font-size: 25px;">Playoff Bracket</h1>



        
    </div>


</div>


<ng-template #template>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
        <p>Are you sure you <br>want to logout?</p>
        <div class="modal-btn d-flex justify-content-around">
            <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
            <button class="btn btn-blue" (click)="confirm()">Yes</button>
        </div>
    </div>
</ng-template>