import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs/internal/Observable";

@Injectable()
export class ManageService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getInterests(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    createInterests(data: any) {
        return this.http.post(this.url + 'category/create', data);
    }

    updateInterest(data: any) {
        return this.http.patch(this.url + 'category/update', data);
    }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    importInterest(data: any) {
        return this.http.post(this.url + 'import/areaOfInterest', data);
    }


    uploadImage(data:any){
        return this.http.post(this.url + 'users/upload', data);
    }

    // getLeaguesList(page: number, pagelimit: number, searchKey: any) {
    //     const url = `${this.url}api/league/list-league-admin?page=${page}&pagelimit=${pagelimit}`;
    //     return this.http.get(url);
    //   }
    getLeaguesList(page: number, pageLimit: number, searchKey: any) {
        const data = {
          page: page,
          pageLimit: pageLimit,
          searchKey: searchKey
        };
      
        return this.http.post(this.url + 'api/league/list-league-admin', data);
      }

    createService(data:any){
        return this.http.post(this.url + 'api/league/create-league-admin', data);
    }


   
  
    deleteService(data: any): Observable<any> {
        return this.http.post<any>(this.url + 'api/league/delete-league-admin', data);
      }

    getServiceBySearch(data:any){
        return this.http.post(this.url + 'admins/search', data);
    }

    getRefreshedPassword(id: any) {
        return this.http.get(this.url + 'api/league/reset-password/' + id, {});
      }


    editService(data:any){
        return this.http.post(this.url + 'api/league/edit-league-admin', data);
    }
}