import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  tabData = [
    { name: 'Andrew Nathan', email: 'andrewnathan@gmail.com', label: 'Head Coach', date: '2023-09-12', profilePicture: 'path_to_image1', description: 'PGA CUP FootBall Match', experience: '3 years' },
    { name: 'Devon Lane', email: 'devonlane@gmail.com', label: 'Assistant Coach', date: '2023-07-05', profilePicture: 'path_to_image1', description: 'Lone Star Youth FootBall', experience: '4 years' },
    { name: 'Eleanor Pena', email: 'eleanorpena@gmail.com', label: 'Head Negotiator', date: '2023-10-14', profilePicture: 'path_to_image1', description: 'SuperStar FootBall League', experience: '5 years' },
    { name: 'Gunesko Cabiyik', email: 'guneskocabiyik@gmail.com', label: 'Waiver Wizard', date: '2023-09-11', profilePicture: 'path_to_image1', description: 'Elite Gridiron League', experience: '3 years' },
    { name: 'Bessie Cooper', email: 'bessiecooper@gmail.com', label: 'Squad Member', date: '2023-09-08', profilePicture: 'path_to_image1', description: 'Stellar FootBall ShowCase', experience: '2.5 years' },
    { name: 'Marvin McKinney', email: 'marvinmckinney@gmail.com', label: 'Anonymous users', date: '2023-10-10', profilePicture: 'path_to_image1', description: 'PowerPlay FootBall League', experience: '2.9 years' },
  ];

  items: any[] = [];

  currentType: string = '';
  userData: any[] = [];
  public ownerHighlighted = false;
  public contractorHighlighted = false;
  public canvas: any;
  public ctx: any;
  public chart: any;
  dashboardData: any = [];
  dashboardAllData: any;
  graphData: any;
  months: number = 6;
  currentUser: any;
  analyticsData: any;
  finalCount: any;
  clientCounts: number = 0;
  careGiverCounts: number = 0;
  bookingCount: number = 0;
  incomeCount: number = 0;
  @ViewChild("template2") template2: TemplateRef<any>;
  @ViewChild("templateImage1") templateImage1: TemplateRef<any>;

  constructor(
    private _header: HeaderService,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private modalService: BsModalService,
    private spinner: SpinnerVisibilityService,
    private datePipe: DatePipe
  ) { }

  positions = ['Head Coach', 'Assistant Coach', 'Head Negotiator', 'Waiver Wizard', 'Squad Member', 'Anonymous users'];

  ngOnInit(): void {
    this.onClickHomeOwner('owner');
    this._header.welcomeUserMsg.next(true);
    this.currentUser = this.authService.currentUserValue;
    this.route.queryParams.subscribe(params => {
      let paramPage = params['page'];
      this.page = parseInt(params['page']);
      if (typeof paramPage == 'undefined') {
        this.page = 1;
      } else {
        this.page = parseInt(paramPage);
      }
    });

    this.selectedTabData = this.tabData.find(tab => tab.name === 'Andrew Nathan');
    this.getDashboardData('', 1, '', this.page, this.itemsPerPage);
    this.getLeaguesList(this.page,this.itemsPerPage)
  }
  pageChanged(event: PageChangedEvent): void {
    this.dashboardList = [];
    this.page = event.page;

    const leagueId = this.selectedItem ? this.selectedItem.id : '';
 // Adjust as necessary
    const position = 1; // Example position, adjust as necessary
    const page = event.page;
    const pageLimit = event.itemsPerPage;
    const searchText = this.searchKey; // Use searchKey here

    this.getDashboardData(leagueId, position, searchText, page, pageLimit);
    window.scrollTo(0, 0);
  }

  leagueList: any[] = [];

  getLeaguesList(page: number, pageLimit: number) {
    this.showLoader = true;
    this.spinner.show();
    let data = {
      page: this.page,
      pageLimit: this.itemsPerPage,
    };
    this.dashboardService.getLeague(data).subscribe((res: any) => {
      if (res.status == 200) {
        this.showLoader = false;
        this.leagueList = res.output.list;
        this.totalCount = res.output.resultCount;
        this.items = this.leagueList.map(league => ({
          id: league._id, // Adjust the id field as per your data structure
          name: league.name
        }));
      }
      this.spinner.hide();
    });
  }
  

  imgURL: any;
  searchKey: string = '';
  reset() {
    this.imgURL = "../../../assets/icons/img-thumb.svg";
  }

  isLoading: boolean = false;
  showLoader: any;
  dashboardList: any;
  getDashboardData(leagueId: string, position: number, searchText: string, page: number, pageLimit: number) {
    this.showLoader = true;
    this.spinner.show();
    this.isLoading = true;

    const payload = {
      leagueId,
      position,
      searchText,
      page,
      pageLimit
    };

    console.log("Payload: ", payload);

    this.dashboardService.getDashboardList(payload).subscribe((res: any) => {
      if (res.status == 200) {
        this.showLoader = false;
        this.dashboardList = res.output.list;
        this.totalCount = res.output.resultCount;
      }
      this.isLoading = false;
      this.spinner.hide();
    });
  }

  readOnly: any = true;
  removeRead() {
    this.readOnly = false;
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  
  // toggleDropdown() {
  //   this.dropdownOpen = !this.dropdownOpen;
  //   let dropdownContent = document.getElementById("dropdownContent");
  //   if (dropdownContent.style.display === "block") {
  //     dropdownContent.style.display = "none";
  //   } else {
  //     dropdownContent.style.display = "block";
  //   }
  // }


  openPopupforDetails(template: any) {
    this.modalRef = this.modalService.show(template);
  }

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'MMM dd, yyyy, h:mm a');
  }

  selectedItem: any | null = null;
  dropdownOpen = false;

  // Function to handle item selection
  selectItem(item: any) {
    this.selectedItem = item; // Update the selected item
    this.dropdownOpen = false;
    console.log("Selected Item: ", this.selectedItem);
    const leagueId = this.selectedItem ? this.selectedItem.id : '';
    this.getDashboardData(leagueId, 1, this.searchKey, this.page, this.itemsPerPage);
  }

  selectedTabData: any;
  selTab: number = 1;
  setupTabs(): void {
    const tabs = document.querySelectorAll('.tab');
    const tabContents = document.querySelectorAll('.tab-content');

    tabs.forEach((tab, index) => {
      tab.addEventListener('click', () => {
        if (tabContents[index] && tabContents[index].id) {
          this.showTabContent(index + 1, tabContents[index].id)
        }
      });
    });
  }

  showTabContent(position: number, searchText: string = '') {
    this.selTab = position;
    this.getDashboardData('', position, searchText, this.currentPage, this.itemsPerPage);
  }

  selectedClient: any = null;

  selectClient(client: any) {
    this.selectedClient = client;
  }

  
  selectedProfilePicture: string;

  ViewUploadImage(client:any) {
    this.selectedProfilePicture = client.userInfo?.profilePic || '../../../assets/images/base_logo_transparent_background (2).png'; // Add this line

    this.openModal(this.templateImage1);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  dateInput: ElementRef;
  onSubmit(f: any) {
    this.searchKey = this.searchText; // Set searchKey to searchText
    const leagueId = this.selectedItem ? this.selectedItem.id : '';
    this.getDashboardData(leagueId, 1, this.searchKey, this.page, this.itemsPerPage); // Ensure searchKey is used here
  }

  // checkEmpty(event: any) {
  //   if (!event.target.value) {
  //     this.searchKey = '';
  //     this.searchText = '';
  //   }
  // }

  checkEmpty(event: any) {
    if (event.target.value === "") {
      this.searchKey = '';
      this.searchText = ''; // Clear searchText when the input is empty
      const leagueId = this.selectedItem ? this.selectedItem.id : '';
      this.getDashboardData(leagueId, 1, '', this.page, this.itemsPerPage);
      document.getElementById('search').blur();
    }
  }

  modalRef: BsModalRef;
  owner: any;
  user: any;
  selectedOwner: any;
  createService(template2: TemplateRef<any>, owner: any) {
    this.selectedOwner = owner;
    this.modalRef = this.modalService.show(template2, { class: 'modal-lg' });
  }

  homeOwnersData: any;
  page: number;
  ownersData: any
  contractorData: any;
  notFound: boolean = false;
  itemsPerPage: number = 10;
  totalCount: number;
  currentPage: number = 1;
  contractorCurrentPage: number = 1;
  ownerCurrentPage: number = 1;

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }

  registeredhomeowners: number = 0;
  registeredcontractors: number = 0;
  registeredcontractorsList: any[] = [];
  registeredhomeownerList: any[] = [];
  contractor: boolean = false;
  typeUser: any
  promotionServicesOption: boolean = false;
  subscriptionOption: boolean = false;
  contractorCount: any;
  onClickContractor(type: any) {
    // intentionally left space for future use
  }

  convertDateTimeFormat(date: any) {
    const formattedDate = this.datepipe.transform(new Date(date), 'MMM dd, yyyy');
    const formattedDateTime = `${formattedDate}`;
    return formattedDateTime;
  }

  shouldDisplayAddressOnNextLine(address: string): string {
    if (address) {
      const words = address.split(' ');
      if (words.length > 10) {
        return words.join('<br>');
      }
    }
    return address ? address : 'N/A';
  }

  pageChangedOwner(event: PageChangedEvent): void {
    this.ownerCurrentPage = event.page;
    this.onClickHomeOwner('owner');
    (<HTMLInputElement>document.getElementById("search")).value = '';
    window.scrollTo(0, 0);
  }

  pageChangedContract(event: PageChangedEvent): void {
    console.log('event', event)
    this.contractorCurrentPage = event.page;
    this.onClickContractor('contractor');
    (<HTMLInputElement>document.getElementById("search")).value = '';
    window.scrollTo(0, 0);
  }

  ownerCount: any;
  searchText: string = '';
  onClickHomeOwner(type: any) {
    // intentionally left space for future use
  }
}
