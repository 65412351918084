import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {  BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { HeaderService } from "src/app/shared/services/header.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { ManageDeletedService } from "./manage-deleted.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { SpinnerVisibilityService } from "ng-http-loader";

@Component({
  selector: 'app-manage-deleted',
  templateUrl: './manage-deleted.component.html',
  styleUrls: ['./manage-deleted.component.scss']
})
export class ManageDeletedComponent implements OnInit {

  constructor(
    private _header: HeaderService,
    private modalService: BsModalService,
    private sharedService: SharedService,
    private toastrService: ToastrService,
    private service: ManageDeletedService,
    private authService: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private spinner: SpinnerVisibilityService


  ) { }
  currentPage: number;

  ngOnInit(): void {
    this._header.managedeletedHeading.next(true);
   
    this.route.queryParams.subscribe(params => {
      let paramPage = params['page'];
      this.page = parseInt(params['page']);
      if (typeof paramPage == 'undefined') {
        this.page = 1;
      } else {
        this.page = parseInt(paramPage);
      }
    });

    if (this.page) {
      this.currentPage = this.page;
    }
   
    this.getDeletedUsersList(this.page,this.itemsPerPage);

  }

  ngOnDestroy() {
    this._header.managedeletedHeading.next(false);
  }

  convertDateTimeFormat(date: any) {
    const formattedDateTime = this.datepipe.transform(new Date(date), 'MMM dd, yyyy, HH:mm');
    return formattedDateTime;
}
showLoader: any;



  page:number;
  itemsPerPage: number = 10;
  deletedList:any;
  totalCount:number;
  getDeletedUsersList(page: number, pageLimit: number) {
    this.showLoader = true;
    this.spinner.show();
    this.service.getDeletedList(page, pageLimit).subscribe((res: any) => {
      if (res.status == 200) {
        this.showLoader = false;
        this.deletedList = res.output.list;
        this.totalCount = res.output.resultCount;
      }
    });
    this.spinner.hide();
  }

}
