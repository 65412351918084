import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { error } from 'src/app/_helpers/error';
import { AuthService } from '../../services/auth.service';
import { FormBuilder } from '@angular/forms';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  submitted: boolean = false;
  error = error.err;
  loginForm:FormGroup;

  // ***********************
  hide = true;
  hide1 = true;
  hide2 = true;
  // *********************

  userType: any = 1;
  

  check: number = 1;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private spinner: SpinnerVisibilityService

  ) { }



  receiveEmail:any;

  ngOnInit() {
    this.initForm();
    let a = localStorage.getItem('receiveEmail');
    let b = a?.slice(1, a.length-1);
    this.loginForm.get("email").patchValue(b);   
   }

  get f() {
    return this.loginForm.controls;
  }

  initForm(){
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }


  myFunction() {
    this.hide = !this.hide;
  }

  hidePassword: boolean = true;

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
showLoader:any;
  login() {
    this.showLoader = true;
    this.spinner.show();
    this.submitted = true;
    if (this.loginForm.invalid || this.loginForm.pristine) return;
    this.submitted = false;
    let user: any = {
      email: this.loginForm.value.email.toLowerCase(),
      password: this.loginForm.value.password,
    };
    
    this.authService.login(user).subscribe(
      (res: any) => {
        if(res.status == 200){
          this.showLoader = false;
          localStorage.setItem('currentUser', JSON.stringify(res.output));
          this.router.navigateByUrl('/analytics-dashboard');
          
          localStorage.removeItem('receiveEmail');
        }
        if(res.status == 404){
          this.showLoader = false;
          this.toastr.error("Invalid email or password");
        }
      },
      
      (err) => {
        console.log(err);
        this.showLoader = false;
        this.toastr.error('Invalid email or password');
      }
    );
    this.spinner.hide(); 
  }


}
