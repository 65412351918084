<body>
  <app-manage-loader *ngIf="showLoader"></app-manage-loader>

  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6">
        <img src="../../../../assets/images/newHuddleImage.png" alt="Description of the image"  style="margin-top: 227px;
    margin-left: 178px;"/>
    </div>
    
      <div class="col-lg-6 text-light">
          
        <form class="template" [formGroup]="loginForm" (submit)="login()">

          <div class="form-group">

            <h3 style="font-size: xx-large;font-weight: bold;margin-top: 20px;">Log In</h3>
            <p style="color: white;margin-top: 30px;">Login to your account to see what<br> happening with your Leagues</p>
            <div class="form-group d-flex borderStyles" style="margin-top: 50px;">
              <img src="../../../../assets/images/Email.png" alt="" class="img-card">
              <input type="email" class="form-control inputStyles rightcard1" placeholder="Email Address" formControlName="email">
            </div>
            <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
              <span *ngIf="f.email.errors.required">
                Email Required
              </span>
              <span *ngIf="f.email.errors.email">
                Invalid Email
              </span>
            </div> 
          </div>
          <div class="form-group">
           
            <!-- <div class="form-group d-flex borderStyles" style="margin-bottom: 20px;">
              <img src="../../../../assets/images/password (1).png" alt="" class="password-card">
              <input [type]="hide ? 'password' : 'text'" class="form-control inputStyles rightCard2" placeholder="Password" formControlName="password">
              <img src="../../../../assets/images/eye.png" alt="" class="password-card" (click)="myFunction()">
              
            </div> -->
            <div class="form-group d-flex borderStyles" style="margin-top: 30px;">
              <img src="../../../../assets/images/password (1).png" alt="" class="password-card">
              <input [type]="hidePassword ? 'password' : 'text'" class="form-control inputStyles rightCard2" placeholder="Password" formControlName="password">
              <mat-icon class="password-card" (click)="togglePasswordVisibility()">
                {{ hidePassword ? 'visibility_off' : 'visibility' }}
              </mat-icon>
            </div>
            
            
            <div class="form-group" >
              <a routerLink="/auth/forgot-password" class="forgot-pass">Forgot password?</a>
            </div>
            <div *ngIf="submitted && f.password.errors" class="alert alert-danger">
              <span *ngIf="f.password.errors.required">
                Password Required
              </span>
              <span *ngIf="f.password.errors.minlength">
                Password must be six or more characters
              </span> 
            </div>
          </div>
        
          <button type="submit" class="btn btn-primary signInBtn" style="background-color: #8261C2;">Sign In</button>
        </form>
      </div>
    </div>
  </div>
</body>


